import { createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';
import { UserResponseWithPermissionsDto } from 'shared-general-libs/dto/user';

export interface UserState {
  user: UserResponseWithPermissionsDto | null;
  error: any | null;
}

export const initialState: UserState = {
  user: null,
  error: null,
};

export const userReducer = createReducer(
  initialState,
  on(
    UserActions.loadSuccess,
    (state, { user }): UserState => ({ ...state, user }),
  ),
  on(
    UserActions.loadFailure,
    (state, { error }): UserState => ({ ...state, error }),
  ),
  on(UserActions.clear, (state): UserState => ({ ...state, user: null })),
);
