import { IModuleTranslationOptions } from '@larscom/ngx-translate-module-loader';

export const DEFAULT_LANGUAGE = 'en';
export const BASE_TRANSLATE_URL = './assets/i18n';

const baseTranslateUrl = BASE_TRANSLATE_URL;

export const TRANSLATION_MODULES: IModuleTranslationOptions = {
  version: 1,
  modules: [
    { baseTranslateUrl },
    { baseTranslateUrl, moduleName: 'nav_menu' },
    { baseTranslateUrl, moduleName: 'page_title' },
    { baseTranslateUrl, moduleName: 'login' },
    { baseTranslateUrl, moduleName: 'forgot_password' },
    { baseTranslateUrl, moduleName: 'topic' },
    { baseTranslateUrl, moduleName: 'emission_factor' },
    { baseTranslateUrl, moduleName: 'role' },
    { baseTranslateUrl, moduleName: 'equipment' },
    { baseTranslateUrl, moduleName: 'form_validation' },
    { baseTranslateUrl, moduleName: 'password_strength' },
    { baseTranslateUrl, moduleName: 'partner' },
    { baseTranslateUrl, moduleName: 'collect' },
    { baseTranslateUrl, moduleName: 'status' },
    { baseTranslateUrl, moduleName: 'notification' },
    { baseTranslateUrl, moduleName: 'category_tree' },
    { baseTranslateUrl, moduleName: 'user' },
    { baseTranslateUrl, moduleName: 'company' },
    { baseTranslateUrl, moduleName: 'permissions' },
    { baseTranslateUrl, moduleName: 'facilities' },
    { baseTranslateUrl, moduleName: 'products' },
    { baseTranslateUrl, moduleName: 'header' },
    { baseTranslateUrl, moduleName: 'task' },
    { baseTranslateUrl, moduleName: 'review' },
    { baseTranslateUrl, moduleName: 'energy_emission_factor' },
    { baseTranslateUrl, moduleName: 'accounting' },
    { baseTranslateUrl, moduleName: 'block_chain' },
    { baseTranslateUrl, moduleName: 'note' },
    { baseTranslateUrl, moduleName: 'org' },
    { baseTranslateUrl, moduleName: 'data_request' },
    { baseTranslateUrl, moduleName: 'units_be' },
    { baseTranslateUrl, moduleName: 'contacts' },
    { baseTranslateUrl, moduleName: 'materiality' },
    // Topics
    {
      baseTranslateUrl,
      moduleName: 'topic/general_disclosure',
      namespace: 'TOPICS:GENERAL_DISCLOSURE',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/materials',
      namespace: 'TOPICS:MATERIALS',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/water_and_effluents',
      namespace: 'TOPICS:WATER_AND_EFFLUENTS',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/emissions',
      namespace: 'TOPICS:EMISSIONS',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/employment',
      namespace: 'TOPICS:EMPLOYMENT',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/anti_corruption',
      namespace: 'TOPICS:ANTI_CORRUPTION',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/training_and_education',
      namespace: 'TOPICS:TRAINING_AND_EDUCATION',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/employee_health_and_safety',
      namespace: 'TOPICS:EMPLOYEE_HEALTH_AND_SAFETY',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/energy',
      namespace: 'TOPICS:ENERGY',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/diversity_and_equal_opportunity',
      namespace: 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/solid_waste',
      namespace: 'TOPICS:SOLID_WASTE',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/economic_performance',
      namespace: 'TOPICS:ECONOMIC_PERFORMANCE',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/indirect_economic_impacts',
      namespace: 'TOPICS:INDIRECT_ECONOMIC_IMPACTS',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/market_presence',
      namespace: 'TOPICS:MARKET_PRESENCE',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/anti_competitive_behavior',
      namespace: 'TOPICS:ANTI_COMPETITIVE_BEHAVIOR',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/supplier_environmental_assessment',
      namespace: 'TOPICS:SUPPLIER_ENVIRONMENTAL_ASSESSMENT',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/labor_management_relations',
      namespace: 'TOPICS:LABOR_MANAGEMENT_RELATIONS',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/non_discrimination',
      namespace: 'TOPICS:NON_DISCRIMINATION',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/freedom_of_association_collective_bargaining',
      namespace: 'TOPICS:FREEDOM_OF_ASSOCIATION',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/marketing_and_labeling',
      namespace: 'TOPICS:MARKETING_AND_LABELING',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/supplier_social_assessment',
      namespace: 'TOPICS:SUPPLIER_SOCIAL_ASSESSMENT',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/public_policy',
      namespace: 'TOPICS:PUBLIC_POLICY',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/security_practices',
      namespace: 'TOPICS:SECURITY_PRACTICES',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/customer_privacy',
      namespace: 'TOPICS:CUSTOMER_PRIVACY',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/procurement_practices',
      namespace: 'TOPICS:PROCUREMENT_PRACTICES',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/nature_loss',
      namespace: 'TOPICS:NATURE_LOSS',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/local_communities',
      namespace: 'TOPICS:LOCAL_COMMUNITIES',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/customer_health_and_safety',
      namespace: 'TOPICS:CUSTOMER_HEALTH_AND_SAFETY',
    },
    {
      baseTranslateUrl,
      moduleName: 'topic/material_topics',
      namespace: 'TOPICS:MATERIAL_TOPICS',
    },
  ],
};
