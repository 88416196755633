import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { IeButtonComponent } from '@ieShared/components/ie-button/ie-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutService } from '@ieCore/services/layout.service';
import { LayoutBg } from '@ieCore/enums/layout';
import { MaterialityService } from '@ieCore/services/materiality.service';
import { requestLoading } from '@ieCore/helpers/request-loading.helper';
import {
  CreateAnswerDto,
  GetFormDataResponseDto,
} from 'shared-general-libs/dist/dto/materiality';
import { RouterLink } from '@angular/router';
import { SurveyAnswerState } from '@ieCore/enums/materiality';
import { take } from 'rxjs/operators';
import { LoadingComponent } from '@ieShared/components/loading/loading.component';

@Component({
  selector: 'app-materiality-survey-answer',
  standalone: true,
  imports: [
    IeButtonComponent,
    TranslateModule,
    NgClass,
    NgOptimizedImage,
    RouterLink,
    LoadingComponent,
  ],
  templateUrl: './materiality-survey-answer.component.html',
  styleUrl: './materiality-survey-answer.component.scss',
})
export class MaterialitySurveyAnswerComponent implements OnInit, OnDestroy {
  @Input() type!: 'stakeholders' | 'business';
  @Input() formId!: string;
  protected readonly SurveyAnswerState = SurveyAnswerState;

  numbers: number[] = Array.from({ length: 10 }, (_, i) => i + 1);
  getSurveyParticipants = requestLoading<GetFormDataResponseDto>();
  saveSurveyAnswer = requestLoading<void>();
  surveyData: GetFormDataResponseDto | undefined;
  selectedItem!: number;
  responseData: Map<any, any> = new Map();
  surveyStatus: SurveyAnswerState | null = null;

  constructor(
    private layoutService: LayoutService,
    private materialityService: MaterialityService,
  ) {}

  ngOnInit() {
    this.layoutService.setLayoutBg(LayoutBg.DARK_BG);
    this.getSurveyDetails();
  }

  getSurveyDetails() {
    this.getSurveyParticipants = this.materialityService.getSurveyParticipants(
      this.formId,
    );

    this.getSurveyParticipants.response.subscribe(
      (data) => {
        this.surveyStatus = SurveyAnswerState.NEW;
        this.surveyData = data;
        if (data.isAnswered) {
          this.surveyStatus = SurveyAnswerState.ALREADY_COMPLETED;
        }
      },
      (e) => {
        if (e?.error?.data[0]?.errors?.includes('SURVEY_ALREADY_FINALIZED')) {
          this.surveyStatus = SurveyAnswerState.ENDED;
        }
      },
    );
  }

  saveData() {
    if (this.responseData.size === this.surveyData?.questionary.length) {
      const res: CreateAnswerDto = {
        answers: Array.from(this.responseData.values()),
      };
      this.saveSurveyAnswer = this.materialityService.saveSurveyAnswer(
        this.formId,
        res,
      );
      this.saveSurveyAnswer.response.pipe(take(1)).subscribe(
        () => (this.surveyStatus = SurveyAnswerState.COMPLETE),
        (e) => {
          if (
            e?.error?.data[0]?.errors?.includes('PARTICIPANT_ALREADY_ANSWERED')
          ) {
            this.surveyStatus = SurveyAnswerState.ALREADY_COMPLETED;
          }
        },
      );
    }
  }

  selectRate(key: string, i: number) {
    this.responseData.set(key, { key: key, value: i });
  }

  ngOnDestroy() {
    this.layoutService.setLayoutBg(LayoutBg.NORMAL_BG);
  }
}
