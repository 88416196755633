import { Injectable } from '@angular/core';
import { FormRepository } from '@ieCore/repositories/form.repository';
import {
  requestLoading,
  RequestLoadingType,
} from '@ieCore/helpers/request-loading.helper';
import {
  ActivityFormResponseDto,
  FormResponseDto,
} from 'shared-general-libs/dto/form';
import {
  PhoneCodeResponseDto,
  RegionResponseDto,
} from 'shared-general-libs/dto/region';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private formRepository: FormRepository) {}

  getFormByName(
    name: string,
    payload: unknown = {},
  ): RequestLoadingType<FormResponseDto[]> {
    return requestLoading(this.formRepository.getFormByName(name, payload));
  }

  getFormByActivityName(
    activity: string,
  ): RequestLoadingType<ActivityFormResponseDto> {
    return requestLoading(this.formRepository.getFormByActivityName(activity));
  }

  getRegions(lang: string): RequestLoadingType<RegionResponseDto[]> {
    return requestLoading(this.formRepository.getRegions(lang));
  }

  getPhoneCodes(): RequestLoadingType<PhoneCodeResponseDto[]> {
    return requestLoading(this.formRepository.getPhoneCodes());
  }
}
