<div class="flex login-container">
  <div class="illustration flex flex-col">
    <div class="logo">
      <img ngSrc="assets/logo-text.svg" alt="logo" height="33" width="268" />
    </div>
    <div class="slogan">
      <div class="title-l mb-12">{{ 'GUEST_TITLE' | translate }}</div>
      <div class="label-l">{{ 'GUEST_SUB_TITLE' | translate }}</div>
    </div>
  </div>
  <div class="login flex justify-center items-center">
    <app-reset-pass-group
      [fg]="forceForm"
      title="LOGIN.FORCE_PASSWORD"
      subTitle="LOGIN.YOU_NEED_UPDATE"
    >
      <ie-button class="mb-24" fullWidth (click)="onSubmit()"
        >{{ 'FORGOT_PASSWORD.RESET_PASSWORD' | translate }}
      </ie-button>
      <ie-button class="mb-24" buttonType="link" fullWidth (click)="toLogin()"
        >{{ 'FORGOT_PASSWORD.BACK_TO_LOGIN' | translate }}
      </ie-button>
    </app-reset-pass-group>
  </div>
</div>
