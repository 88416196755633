import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ReactiveFormsModule } from '@angular/forms';

import { IeIconsComponent } from '@ieShared/components/ie-icons/ie-icons.component';
import { HeaderComponent } from '@ieShared/components/header/header.component';
import { NavBarComponent } from '@ieShared/components/nav-bar/nav-bar.component';
import { LayoutService } from '@ieCore/services/layout.service';
import { LayoutState } from '@ieCore/enums/layout';
import { NAV_MENU } from '@ieCore/consts/nav-menu.consts';
import { AuthService } from '@ieCore/services/auth.service';
import { NavItem } from '@ieShared/interfaces/nav-bar.interface';
import { NzDividerComponent } from 'ng-zorro-antd/divider';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    NgClass,
    IeIconsComponent,
    NgOptimizedImage,
    NzInputModule,
    ReactiveFormsModule,
    HeaderComponent,
    NavBarComponent,
    NzDividerComponent,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent implements OnInit {
  closed = false;

  menuConfig: NavItem[];

  constructor(
    private layoutService: LayoutService,
    private authService: AuthService,
  ) {
    this.menuConfig = NAV_MENU[this.authService.getUserTypeFromToken()];
  }

  ngOnInit() {
    this.closed = this.layoutService.getMenuState() === LayoutState.CLOSED;
  }

  toggle() {
    this.closed = !this.closed;
    this.layoutService.setMenuState(
      this.closed ? LayoutState.CLOSED : LayoutState.OPENED,
    );
  }
}
