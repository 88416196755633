import { Injectable } from '@angular/core';
import { RoutesConfig } from '@ieApp/routes.config';
import { UserType } from 'shared-general-libs/type/user';
import { NAV_MENU } from '@ieCore/consts/nav-menu.consts';
import { UserService } from '@ieCore/services/user.service';
import logger from '@ieShared/utils/logger.utils';

type RoutesConfigType = typeof RoutesConfig;
type RouteFunction = RoutesConfigType[keyof RoutesConfigType];

@Injectable({
  providedIn: 'root',
})
export class NavService {
  public to = RoutesConfig;

  constructor(private userService: UserService) {}

  static get(routeFunc: RouteFunction) {
    const urlArr = routeFunc();
    return urlArr[urlArr.length - 1];
  }

  getRouteByUserTypeAndPermission(
    userType: UserType,
    userPermissions?: string[],
  ) {
    const menuItems = NAV_MENU[userType];
    let pageWithAccess;

    if (userPermissions === undefined) {
      logger.error('User permissions are not defined');
      return;
    }

    for (const item of menuItems) {
      const hasPermission = this.userService.checkForPermission(
        userPermissions,
        item.permission,
      );
      if (hasPermission) {
        pageWithAccess = item;
        break;
      }
    }

    return pageWithAccess!.route!().join('/');
  }
}
