import { environment } from '@ieEnv/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginatedResponseDto } from 'shared-general-libs/dto/pagination';
import { removeEmptyFields } from '@ieShared/utils/query.utils';
import { ContactsRequestInterface } from '@ieShared/interfaces/contacts.interface';
import {
  ContactResponseDto,
  CreateAnswerDto,
  CreateContactDto,
  CreateSurveyDto,
  GetFormDataResponseDto,
  ManageSurveyParticipantsDto,
  SurveyResponseDto,
  SurveyResponseWithStatsDto,
  UpdateSurveyDto,
  UpdateSurveyEndDateDto,
} from 'shared-general-libs/dto/materiality';
import {
  ParticipantsPaginationInterface,
  SurveyRequestInterface,
} from '@ieShared/interfaces/materiality.interface';
import { ImportanceType } from 'shared-general-libs/type/materiality';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MaterialityRepository {
  private readonly apiUrl: string = environment.materialitySurvey;

  constructor(private readonly http: HttpClient) {}

  getContacts(
    pagination: ContactsRequestInterface,
  ): Observable<PaginatedResponseDto<ContactResponseDto>> {
    return this.http.post<PaginatedResponseDto<ContactResponseDto>>(
      `${this.apiUrl}/contacts/search`,
      { ...removeEmptyFields(pagination) },
    );
  }

  createContact(payload: CreateContactDto) {
    return this.http.post<ContactResponseDto>(
      `${this.apiUrl}/contacts`,
      payload,
    );
  }

  editContact(payload: CreateContactDto, id: string) {
    return this.http.patch<ContactResponseDto>(
      `${this.apiUrl}/contacts/${id}`,
      payload,
    );
  }

  deleteContact(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/contacts/${id}`);
  }

  getContactById(id: string): Observable<ContactResponseDto> {
    return this.http.get<ContactResponseDto>(`${this.apiUrl}/contacts/${id}`);
  }

  getSurveys(
    pagination: SurveyRequestInterface,
  ): Observable<PaginatedResponseDto<SurveyResponseWithStatsDto>> {
    return this.http.get<PaginatedResponseDto<SurveyResponseWithStatsDto>>(
      `${this.apiUrl}/survey-participants/surveys-with-stats`,
      {
        params: removeEmptyFields(pagination),
      },
    );
  }

  getSurveyById(id: string): Observable<SurveyResponseDto> {
    return this.http.get<SurveyResponseDto>(`${this.apiUrl}/surveys/${id}`);
  }

  getSurveyStatsById(id: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/survey-participants/stats/${id}`)
      .pipe(
        map((data) => {
          if (!data) {
            return {
              [ImportanceType.BUSINESS]: {
                sent: 0,
                answered: 0,
                total: 0,
                notSent: 0,
                progress: 0,
                inProgress: 0,
              },
              [ImportanceType.STAKEHOLDERS]: {
                sent: 0,
                answered: 0,
                total: 0,
                notSent: 0,
                progress: 0,
                inProgress: 0,
              },
            };
          }
          const result: any = {};
          for (const type of Object.keys(data)) {
            result[type] = {
              ...data[type],
              notSent: data[type].total - data[type].sent,
              inProgress: data[type].sent - data[type].answered,
              progress:
                data[type].answered && data[type].sent
                  ? (data[type].answered / data[type].sent) * 100
                  : 0,
            };
          }
          return result;
        }),
      );
  }

  getSurveyParticipants(id: string): Observable<GetFormDataResponseDto> {
    return this.http.get<GetFormDataResponseDto>(
      `${this.apiUrl}/survey-forms/${id}`,
    );
  }

  getSurveyParticipantsList(
    id: string,
    pagination: ParticipantsPaginationInterface,
  ): Observable<PaginatedResponseDto<ContactResponseDto>> {
    return this.http.get<PaginatedResponseDto<ContactResponseDto>>(
      `${this.apiUrl}/survey-participants/${id}`,
      {
        params: removeEmptyFields(pagination),
      },
    );
  }

  getAggregatedStats(id: string): Observable<any> {
    return this.http.get<PaginatedResponseDto<ContactResponseDto>>(
      `${this.apiUrl}/answers/aggregate/${id}`,
    );
  }

  getAggregatedByContacts(
    id: string,
    topicId: string,
    pagination: any,
  ): Observable<any> {
    return this.http.get<PaginatedResponseDto<ContactResponseDto>>(
      `${this.apiUrl}/answers/average-by-contacts/${id}/${topicId}`,
      {
        params: removeEmptyFields(pagination),
      },
    );
  }

  saveSurveyAnswer(id: string, payload: CreateAnswerDto): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/survey-forms/answer/${id}`,
      payload,
    );
  }

  finalize(id: string, topics: string[]): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}/surveys/finalize/${id}`, {
      topics,
    });
  }

  sendSurvey(
    surveyId: string,
    importanceType: ImportanceType,
  ): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/survey-participants/send/${surveyId}`,
      {
        importanceType,
      },
    );
  }

  createSurvey(payload: CreateSurveyDto): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/surveys`, payload);
  }

  addStakeholders(
    id: string,
    payload: ManageSurveyParticipantsDto,
  ): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/survey-participants/manage-many/${id}`,
      payload,
    );
  }

  getContactsAnswer(surveyId: string, contactIds: string[]): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/answers/${surveyId}`, {
      params: removeEmptyFields({
        page: 1,
        itemsPerPage: 1000,
        contacts: contactIds,
      }),
    });
  }

  updateSurvey(id: string, payload: UpdateSurveyDto): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/surveys/${id}`, payload);
  }

  updateSurveyReminderDay(
    id: string,
    payload: UpdateSurveyEndDateDto,
  ): Observable<void> {
    return this.http.patch<void>(
      `${this.apiUrl}/survey-reminders/update-end-date/${id}`,
      payload,
    );
  }
}
