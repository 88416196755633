import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PageTitleService } from '@ieCore/services/page-title.service';
import { SvgIconsService } from '@ieCore/services/svg-icons.service';
import { RegionService } from '@ieCore/services/region.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor(
    private pageTitleService: PageTitleService,
    private svgIconService: SvgIconsService,
    private regionService: RegionService,
  ) {
    this.pageTitleService.init();
    this.svgIconService.loadSvgSprite('assets/ie-icons.svg');
    this.regionService.initRegions();
  }
}
