import { Component, OnInit } from '@angular/core';
import { NavService } from '@ieCore/services/nav.service';
import { IeIconsComponent } from '@ieShared/components/ie-icons/ie-icons.component';
import { CommonModule } from '@angular/common';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { TranslateModule } from '@ngx-translate/core';
import { requestLoading } from '@ieCore/helpers/request-loading.helper';
import { AuthService } from '@ieCore/services/auth.service';
import { IeButtonComponent } from '@ieShared/components/ie-button/ie-button.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzBadgeModule } from 'ng-zorro-antd/badge';

import { PaginatedResponseDto } from 'shared-general-libs/dto/pagination';
import {
  NotificationResponseDto,
  UpdateNotificationInAppStatusDto,
} from 'shared-general-libs/dto/notification';
import { NotificationService } from '@ieCore/services/notification.service';
import { TimeagoModule } from 'ngx-timeago';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import {
  NotificationComponentTypes,
  NotificationRequestParams,
  NotificationStatus,
} from '@ieShared/interfaces/notifications.interface';
import { IeBreadcrumbsComponent } from '@ieShared/components/ie-breadcrumbs/ie-breadcrumbs.component';
import { Store } from '@ngrx/store';
import { selectUser } from '@ieApp/store/user/user.selectors';
import { ThemeService } from '@ieCore/services/theme.service';
import { ThemeMode } from '@ieCore/enums/theme';
import { InAppStatus } from 'shared-general-libs/type/notification';
import { NotificationsComponent } from '@ieShared/components/notifications/notifications.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { deepCopy } from 'shared-general-libs/utils';
import { Router, RouterLink } from '@angular/router';
import { environment } from '@ieEnv/environment';
import { NavigatePipe } from '@ieShared/pipes/navigate.pipe';
import { UserType } from 'shared-general-libs/type/user';
import { AvatarComponent } from '@ieShared/components/avatar/avatar.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    IeIconsComponent,
    TranslateModule,
    NzPopoverModule,
    IeButtonComponent,
    NzBadgeModule,
    NzAvatarModule,
    TimeagoModule,
    CommonModule,
    NzGridModule,
    NzDropDownModule,
    IeBreadcrumbsComponent,
    NotificationsComponent,
    NzModalModule,
    NavigatePipe,
    RouterLink,
    AvatarComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  protected readonly UserType = UserType;

  logoutRequest = requestLoading();
  getNotificationsReq =
    requestLoading<PaginatedResponseDto<NotificationResponseDto>>();

  notifications: NotificationResponseDto[] = [];
  notificationBadges = 0;
  notificationCount = 0;
  notificationPopover = false;
  notificationModal = false;

  defaultParams: NotificationRequestParams = {
    page: 1,
    itemsPerPage: 10,
  };

  params!: NotificationRequestParams;

  theme = this.themeService.getCurrentTheme();
  ThemeMode = ThemeMode;
  NotificationComponentTypes = NotificationComponentTypes;

  showUserDropDown = false;
  showUserDropDownIcon = false;
  $user = this.store.select(selectUser);

  appVersion = environment.appVersion;

  settingsRouteMappingByUserType: any = {
    [UserType.SUPER_ADMIN]: null,
    [UserType.ENTERPRISE_USER]: this.navService.to.enterpriseCompany,
    [UserType.ENTERPRISE_ADMIN]: this.navService.to.enterpriseCompany,
    [UserType.PARTNER_USER]: this.navService.to.partnerChangePassword,
    [UserType.PORTFOLIO_USER]: null,
    [UserType.CERTIFIER_USER]: null,
    [UserType.AUDITOR_USER]: null,
  };

  constructor(
    private readonly store: Store,
    private authService: AuthService,
    private notificationService: NotificationService,
    public navService: NavService,
    private themeService: ThemeService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.getNotifications();
  }

  logout(): void {
    const authData = this.authService.getAuthData();
    const payload = {
      refreshToken: authData.refreshToken,
    };
    this.logoutRequest = this.authService.logout(payload);
    this.logoutRequest.response.subscribe({
      next: () => {},
      error: () => {},
    });
  }

  getNotifications(status = NotificationStatus.ALL) {
    this.params = deepCopy(this.defaultParams);

    if (status === NotificationStatus.UNREAD) {
      this.params.inappStatus = InAppStatus.UNREAD;
    }

    this.getNotificationsReq = this.notificationService.getNotifications(
      this.params,
    );

    this.getNotificationsReq.response.subscribe((data) => {
      this.notifications = data.items;
      this.notificationBadges = data.items.reduce(
        (acc, item) => acc + (item.inappStatus === InAppStatus.UNREAD ? 1 : 0),
        0,
      );

      if (status === NotificationStatus.ALL) {
        this.notificationCount = data.total;
      }
    });
  }

  showNotificationPopover() {
    this.notificationPopover = true;
    this.getNotifications();
  }

  loadMoreNotifications() {
    this.params.page += 1;
    this.getNotificationsReq = this.notificationService.getNotifications(
      this.params,
    );

    this.getNotificationsReq.response.subscribe((data) => {
      this.notifications = [...this.notifications, ...data.items];
      this.notificationBadges = this.notifications.reduce(
        (acc, item) => acc + (item.inappStatus === InAppStatus.UNREAD ? 1 : 0),
        0,
      );
    });
  }

  userDropdownStateChanged($event: boolean) {
    this.showUserDropDown = $event;
    setTimeout(() => (this.showUserDropDownIcon = $event), $event ? 0 : 400);
  }

  markAsRead(notification?: NotificationResponseDto) {
    if (notification?.inappStatus !== InAppStatus.READ) {
      const payload: UpdateNotificationInAppStatusDto = {
        inappStatus: InAppStatus.READ,
      };

      const markRequest =
        notification && notification.id
          ? this.notificationService.markAsRead(notification?.id, payload)
          : this.notificationService.markAsReadAll(payload);
      markRequest.response.subscribe(() => this.getNotifications());
    }

    if (notification?.recordLink) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([notification?.recordLink]).then();
        this.notificationPopover = false;
      });
    }
  }

  seeAll() {
    this.notificationPopover = false;
    this.notificationModal = true;
  }
}
