import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@ieEnv/environment';
import { PaginationInterface } from '@ieShared/interfaces/pagination.interface';
import { PaginatedResponseDto } from 'shared-general-libs/dto/pagination';
import {
  NotificationResponseDto,
  UpdateNotificationInAppStatusDto,
} from 'shared-general-libs/dto/notification';
import { removeEmptyFields } from '@ieShared/utils/query.utils';

@Injectable({
  providedIn: 'root',
})
export class NotificationRepository {
  private readonly apiUrl: string = environment.notificationHost;

  constructor(private http: HttpClient) {}

  getNotifications(
    params: PaginationInterface,
  ): Observable<PaginatedResponseDto<NotificationResponseDto>> {
    return this.http.get<PaginatedResponseDto<NotificationResponseDto>>(
      `${this.apiUrl}/notification`,
      { params: removeEmptyFields(params) },
    );
  }

  markAsRead(
    id: string,
    payload: UpdateNotificationInAppStatusDto,
  ): Observable<NotificationResponseDto> {
    return this.http.put<NotificationResponseDto>(
      `${this.apiUrl}/notification/mark/${id}`,
      payload,
    );
  }

  markAsReadAll(
    payload: UpdateNotificationInAppStatusDto,
  ): Observable<NotificationResponseDto> {
    return this.http.post<NotificationResponseDto>(
      `${this.apiUrl}/notification/mark-all`,
      payload,
    );
  }
}
