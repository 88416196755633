import { Injectable } from '@angular/core';
import {
  requestLoading,
  RequestLoadingType,
} from '@ieCore/helpers/request-loading.helper';
import { PaginatedResponseDto } from 'shared-general-libs/dto/pagination';
import { MaterialityRepository } from '@ieCore/repositories/materiality.repository';
import { ContactsRequestInterface } from '@ieShared/interfaces/contacts.interface';
import {
  ContactResponseDto,
  CreateAnswerDto,
  CreateContactDto,
  CreateSurveyDto,
  GetFormDataResponseDto,
  ManageSurveyParticipantsDto,
  SurveyResponseDto,
  SurveyResponseWithStatsDto,
  UpdateSurveyDto,
  UpdateSurveyEndDateDto,
} from 'shared-general-libs/dto/materiality';
import {
  ParticipantsPaginationInterface,
  SurveyRequestInterface,
} from '@ieShared/interfaces/materiality.interface';
import { ImportanceType } from 'shared-general-libs/type/materiality';

@Injectable({
  providedIn: 'root',
})
export class MaterialityService {
  constructor(private readonly materialityRepo: MaterialityRepository) {}

  getContacts(
    pagination: ContactsRequestInterface,
  ): RequestLoadingType<PaginatedResponseDto<ContactResponseDto>> {
    return requestLoading(this.materialityRepo.getContacts(pagination));
  }

  getContactById(id: string): RequestLoadingType<ContactResponseDto> {
    return requestLoading(this.materialityRepo.getContactById(id));
  }

  createContact(
    payload: CreateContactDto,
  ): RequestLoadingType<ContactResponseDto> {
    return requestLoading(this.materialityRepo.createContact(payload));
  }

  editContact(
    payload: CreateContactDto,
    id: string,
  ): RequestLoadingType<ContactResponseDto> {
    return requestLoading(this.materialityRepo.editContact(payload, id));
  }

  deleteContact(id: string): RequestLoadingType<void> {
    return requestLoading(this.materialityRepo.deleteContact(id));
  }

  getSurveys(
    pagination: SurveyRequestInterface,
  ): RequestLoadingType<PaginatedResponseDto<SurveyResponseWithStatsDto>> {
    return requestLoading(this.materialityRepo.getSurveys(pagination));
  }

  getSurveyById(id: string): RequestLoadingType<SurveyResponseDto> {
    return requestLoading(this.materialityRepo.getSurveyById(id));
  }

  getSurveyStatsById(id: string): RequestLoadingType<any> {
    return requestLoading(this.materialityRepo.getSurveyStatsById(id));
  }

  createSurvey(payload: CreateSurveyDto): RequestLoadingType<void> {
    return requestLoading(this.materialityRepo.createSurvey(payload));
  }

  addStakeholders(
    id: string,
    payload: ManageSurveyParticipantsDto,
  ): RequestLoadingType<void> {
    return requestLoading(this.materialityRepo.addStakeholders(id, payload));
  }

  updateSurvey(id: string, payload: UpdateSurveyDto): RequestLoadingType<void> {
    return requestLoading(this.materialityRepo.updateSurvey(id, payload));
  }

  getSurveyParticipants(
    id: string,
  ): RequestLoadingType<GetFormDataResponseDto> {
    return requestLoading(this.materialityRepo.getSurveyParticipants(id));
  }

  getSurveyParticipantsList(
    id: string,
    pagination: ParticipantsPaginationInterface,
  ): RequestLoadingType<PaginatedResponseDto<ContactResponseDto>> {
    return requestLoading(
      this.materialityRepo.getSurveyParticipantsList(id, pagination),
    );
  }

  getAggregatedStats(id: string): RequestLoadingType<any> {
    return requestLoading(this.materialityRepo.getAggregatedStats(id));
  }

  getAggregatedByContacts(
    id: string,
    topicId: string,
    pagination: any,
  ): RequestLoadingType<any> {
    return requestLoading(
      this.materialityRepo.getAggregatedByContacts(id, topicId, pagination),
    );
  }

  saveSurveyAnswer(
    id: string,
    payload: CreateAnswerDto,
  ): RequestLoadingType<void> {
    return requestLoading(this.materialityRepo.saveSurveyAnswer(id, payload));
  }

  finalize(id: string, topics: string[]): RequestLoadingType<void> {
    return requestLoading(this.materialityRepo.finalize(id, topics));
  }

  sendSurvey(
    id: string,
    importanceType: ImportanceType,
  ): RequestLoadingType<void> {
    return requestLoading(this.materialityRepo.sendSurvey(id, importanceType));
  }

  updateSurveyReminderDay(
    id: string,
    payload: UpdateSurveyEndDateDto,
  ): RequestLoadingType<void> {
    return requestLoading(
      this.materialityRepo.updateSurveyReminderDay(id, payload),
    );
  }

  getContactsAnswer(
    surveyId: string,
    contactIds: string[],
  ): RequestLoadingType<any> {
    return requestLoading(
      this.materialityRepo.getContactsAnswer(surveyId, contactIds),
    );
  }
}
