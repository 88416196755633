import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormError } from '@ieCore/enums/form-error';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateWithFallbackPipe } from '@ieShared/pipes/translate-with-fallback.pipe';

@Component({
  selector: 'app-ie-form-error',
  standalone: true,
  imports: [TranslateModule, TranslateWithFallbackPipe],
  templateUrl: './ie-form-error.component.html',
  styleUrl: './ie-form-error.component.scss',
})
export class IeFormErrorComponent {
  @Input() field!: AbstractControl<unknown>;

  constructor(private translate: TranslateService) {}

  formErrors = FormError;

  translateCompareField(errors: any, errorCode: any): string {
    const compareField = errors[errorCode]?.compareField || [];
    return compareField
      .map((field: string) => this.translate.instant(field))
      .join(', ');
  }
}
