import { environment } from '@ieEnv/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ActivityFormResponseDto,
  FormResponseDto,
} from 'shared-general-libs/dto/form';
import { Observable } from 'rxjs';
import {
  PhoneCodeResponseDto,
  RegionResponseDto,
} from 'shared-general-libs/dto/region';

@Injectable({
  providedIn: 'root',
})
export class FormRepository {
  private readonly apiUrl: string = environment.formHost;

  constructor(private http: HttpClient) {}

  getFormByName(name: string, payload: unknown): Observable<FormResponseDto[]> {
    return this.http.post<FormResponseDto[]>(
      `${this.apiUrl}/form/get/${name}`,
      payload,
    );
  }

  getFormByActivityName(activity: string): Observable<ActivityFormResponseDto> {
    return this.http.post<ActivityFormResponseDto>(
      `${this.apiUrl}/form/get/activity/${activity}`,
      {},
    );
  }

  getRegions(lang: string): Observable<RegionResponseDto[]> {
    return this.http.get<RegionResponseDto[]>(
      `${this.apiUrl}/form/regions/${lang}`,
    );
  }

  getPhoneCodes(): Observable<PhoneCodeResponseDto[]> {
    return this.http.get<PhoneCodeResponseDto[]>(
      `${this.apiUrl}/form/phone-codes`,
    );
  }
}
