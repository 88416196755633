import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IeButtonComponent } from '@ieShared/components/ie-button/ie-button.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { markAllAsTouched } from '@ieCore/helpers/form.helper';
import { FormError } from '@ieCore/enums/form-error';
import { Router } from '@angular/router';
import { NavService } from '@ieCore/services/nav.service';
import { CustomValidators } from '@ieCore/helpers/validation.helper';
import { UserService } from '@ieCore/services/user.service';
import { requestLoading } from '@ieCore/helpers/request-loading.helper';
import { ResetPassGroupComponent } from '@ieShared/components/reset-pass-group/reset-pass-group.component';
import { AuthService } from '@ieCore/services/auth.service';
import logger from '@ieShared/utils/logger.utils';

@Component({
  selector: 'app-force-password-reset',
  standalone: true,
  imports: [
    NgOptimizedImage,
    TranslateModule,
    IeButtonComponent,
    ReactiveFormsModule,
    FormsModule,
    ResetPassGroupComponent,
  ],
  templateUrl: './force-password-reset.component.html',
  styleUrl: './force-password-reset.component.scss',
})
export class ForcePasswordResetComponent {
  forceResetReq = requestLoading();
  forceForm: FormGroup;
  formErrors = FormError;

  constructor(
    private fb: FormBuilder,
    public navService: NavService,
    public userService: UserService,
    private router: Router,
    private authService: AuthService,
  ) {
    this.forceForm = this.fb.group(
      {
        current_pass: ['', [Validators.required, CustomValidators.password()]],
        new_pass: ['', [Validators.required, CustomValidators.password()]],
        confirm_pass: ['', [Validators.required]],
      },
      {
        validators: CustomValidators.passwordMatchValidator(
          'new_pass',
          'confirm_pass',
        ),
      },
    );
  }

  onSubmit(): void {
    if (!this.forceForm.valid) {
      markAllAsTouched(this.forceForm);
      return;
    }

    this.forceResetReq = this.userService.forceResetPassword({
      newPassword: this.forceForm.value.new_pass,
      oldPassword: this.forceForm.value.current_pass,
    });

    this.forceResetReq.response.subscribe({
      next: () => {
        logger.log('password changed successfully');
        this.toLogin();
      },
      error: (err) => {
        if (err.error && err.error.data) {
          this.forceForm.setErrors({ [err?.error?.data[0].errors[0]]: true });
        }
      },
    });
  }

  toLogin() {
    this.authService.removeAuthData();
    this.router
      .navigate(this.navService.to.login())
      .then(() => logger.log('Navigated to login'));
  }
}
