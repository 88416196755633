import { environment } from '@ieEnv/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CategoryResponseDto } from 'shared-general-libs/dto/category';
import { SaveTopicDto } from 'shared-general-libs/dto/topic';

@Injectable({
  providedIn: 'root',
})
export class TopicRepository {
  private readonly apiUrl: string = environment.topicHost;

  constructor(private readonly http: HttpClient) {}

  getTopics(): Observable<CategoryResponseDto[]> {
    return this.http.get<CategoryResponseDto[]>(`${this.apiUrl}/topics/forest`);
  }

  getEnabledTopicsForEnterprise(): Observable<CategoryResponseDto[]> {
    return this.http.get<CategoryResponseDto[]>(
      `${this.apiUrl}/topics/forest-enabled`,
    );
  }

  getAdminTopics(orgId: string): Observable<CategoryResponseDto[]> {
    return this.http.get<CategoryResponseDto[]>(
      `${this.apiUrl}/admin/topics/forest/${orgId}`,
    );
  }

  getTopicsTree(name: string): Observable<CategoryResponseDto> {
    return this.http.get<CategoryResponseDto>(
      `${this.apiUrl}/topics/tree/${name}`,
    );
  }

  saveTopics(data: SaveTopicDto[]): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/topics/save`, data);
  }

  saveAdminTopics(orgId: string, data: SaveTopicDto[]): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/admin/topics/save/${orgId}`,
      data,
    );
  }

  saveTopicsForAdmin(orgId: string, data: SaveTopicDto[]): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/admin/topics/save/${orgId}`,
      data,
    );
  }
}
